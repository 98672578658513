@media only screen 
and (device-width: 390px) 
and (device-height: 844px) 
and (-webkit-device-pixel-ratio: 3) { 
    .box{
        width:70% !important;
        height:1.2rem
    }
    .contactchild{
        padding-left: 10%;
    }
    .contactsection{
        padding-top: 5rem !important;
        padding-left: 2rem;
    }
    .button{
        background-color: whitesmoke;
        color: #000;
        font-family: 'Inconsolata', monospace;
        position: relative;
        left: 1rem;
        width:80%
    }
    .contacttext {
        padding-bottom: 3rem !important;
    }

}


/* Iphone media queries 6/7/8*/
@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px)  {

    .box{
        width:70% !important;
        height:1.2rem
    }
    .contactchild{
        padding-left: 10%;
    }
    .contactsection{
        padding-top: 5rem !important;
        padding-left: 2rem;
    }
    .button{
        background-color: whitesmoke;
        color: #000;
        font-family: 'Inconsolata', monospace;
        position: relative;
        left: 1rem;
        width:80%
    }
    .contacttext {
        padding-bottom: 3rem !important;
    }

}

/* ipad queries */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) {

    .subjectbox{
        width: 40rem !important;
    }
    .messagebox {
        width: 40rem !important;
}
  }


  @media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    .subjectbox{
        width: 32rem !important;
    }
    .messagebox {
        width: 32rem !important;
}
   }

   @media only screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .subjectbox{
        width: 32rem !important;
    }
    .messagebox {
        width: 32rem !important;
}
   }



.contactsection{
    padding-top: 8rem;
    padding-bottom: 4rem;
}

.contacttext{
    padding-bottom: 6rem;
}

.contactparent{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactchild{
    margin: 0rem;

}

.inputinfo{
    margin: -0.3rem;
    line-height: 3;
}

.box{
    margin-bottom: 1.7rem;
    padding: 0.7rem;
    border: 1.3px solid #000;
    width: 28rem;
    font-size: 16px;
}

.button{
    border: 1.3px solid #000;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-top: 2rem;
    font-size: 1.1rem;
    font-family: 'Inconsolata', monospace;
    /* letter-spacing: .5rem; */
}

.button:hover{
    background-color: rgb(165, 163, 167);
}

/* .namesection{
    margin-right: 5rem;

} */

.subjectbox{
    width: 60rem
}

.messagebox{
    width: 60rem;
    height: 17rem;
}