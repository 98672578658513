@media only screen
  and (max-device-width: 768px)
  and (orientation: portrait) {
    .homepage{
        padding-left: 0 !important;
        padding-right: 0 !important;
        padding-top: 70% !important;
    }

    p{
      line-height: 1.5;
    }

    h1{
      font-size: 1.8rem !important;
    }

  }

  @media only screen
  and (max-device-width: 768px)
  and (orientation: landscape){
    .homepage{
      position: relative;
      left:4rem;
      padding-top: 0%;
    }
    
  }

/* iPad models */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
  .homepage{
    padding-top: 34% !important;
  }
}

@media only screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .homepage{
    padding-top: 27% !important;
  }
}

/* ipad air css */

@media only screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
  .homepage{
    padding-top: 47% !important;
    padding-left: 2% !important;
    padding-right: 0% !important;
  }
}

.homepage{
    padding-left: 15%;
    padding-right: 15%;
    position: relative;
    bottom: 7rem;
    padding-top: 20%;
}

html {
    scroll-behavior: smooth;
  }