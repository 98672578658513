@media (max-width: 45em) {
  .option{
    display: none !important;
  } 
}


/* @media only screen
  and (max-device-width: 768px)
  and (orientation: portrait) {

    .option{
      display: none !important;
    } */
    /* .navbarbody{
      height:fit-content !important
    }

    .titletext{
      padding-left: 4px !important;
      position: relative;
      left:9%

    }

    .ladytext{
      position: relative !important;
      font-size: 1.5rem !important;
      top: .5rem !important;
    }

    .wdtext{
      font-size: 2rem !important;
      letter-spacing: 0rem !important;
    }

    .option{
      margin: 0% !important;
      display: inline !important;
      font-size: .5rem !important;
      padding: 0 !important;
    }

    .navchoices{
      bottom: 2% !important;
      right: 45% !important;
      display: block

    }

    ul{
      padding-left: 12% !important;
    } */


/* }

@media only screen
  and (max-device-width: 768px)
  and (orientation: landscape){ */
    /* .navbarbody{
      height:100% !important;
      position: fixed !important;  
      top: 0 !important; 
      left: 5 !important; 
      bottom: 0 !important; 
      width: 14rem !important; */
    /* }
    .wdtext{
      position: relative;
      right: 2rem;
      letter-spacing: -.05rem;
      font-size: 25px !important;
    }
    .ladytext{
      margin-top: 0%;
      letter-spacing: 0rem !important;
      position: relative;
      right: 2rem !important;
    }
    .navchoices{
      bottom: 2% !important;
      right: 1rem !important;

    }
    ul{
      padding-top: 5%;
      display: block !important;
      position: relative !important;
      right:1rem !important
    } */
  /* } */

  /* ipad air */
  /* @media only screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) { */
    /* .navchoices{
      bottom: 5% !important;
      right: 23% !important;
    }
    .ladytext{
      margin-top: 6%;
    }
     */
  /* } */


/* Only for setting up the element */
header{
  width: 106%;
  position: fixed;
  left: -3%;
  height: 13%;
  top: -2%;
}

.wdtext{
  font-size: 2.5rem;
  margin-top: 0%;
  padding-top: 1%;
}

.ladytext{
  color: rgb(125, 124, 124);
  margin-bottom: 0px !important;
}

h2{
    letter-spacing: 4px;
    font-size: 30px;
    margin-bottom: 5px;
    color: rgb(0, 0, 0);
}
h1{
    font-size: 45px;
    color:black;
    margin-top: 0px;
}
.titletext{
    padding-top: 1rem;
    padding-left: 5rem;
    text-decoration: none;
    font-size: 20rem;
}
.navbarline{
    margin-top: 8rem;
    width: 135.67%;
    position: relative;
    left: 3rem;
    border: none;
    border-top: 2px solid #9E9E9E;
}

.navtext{
    color:rgb(0, 0, 0);
    font-size: 18px;
}

.option{
    margin: 0%;
    padding: 20px;
    display: inline;
}

.navchoices{
    position: relative;
    bottom: 85px;
    bottom: 49%;
    right: 6rem;
    display: flex;
    flex-direction: row-reverse;
  }

  ul {
    list-style-type: none;
    position: relative;
    margin: 0.4rem;
    bottom: 26%;
    left: 3%;
  }

  .navbarbody{
    margin-bottom: 0px;
    z-index: 1000;
    background-color: #eeecec;
    height: 10rem;
  }

a{
    text-decoration: none;
  }
