@media only screen 
and (device-width: 390px) 
and (device-height: 844px) 
and (-webkit-device-pixel-ratio: 3) { 

    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        /* Add other styles to move the image to a different location here */
      }
    .about-container{
        display: block !important;
    }

    hr{
        left: -6.5rem !important;
        width: 70.67% !important;
    }

}
@media only screen
and (max-device-width: 768px)
and (orientation: landscape){
    .about-container{

        margin-top: 0% !important;
    }
    .aboutimage{
        height: 0px;
        width:0px;
        /* Add other styles to move the image to a different location here */
      }

}

/* Iphone media queries 6/7/8*/
@media only screen 
and (min-device-height: 350px) 
and (max-device-width: 420px)  {

    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        /* Add other styles to move the image to a different location here */
      }

      .about-container{
        display: block !important;
    }

}

/* iPad models */
@media only screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
   
    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        /* Add other styles to move the image to a different location here */
      }

      .about-container{
        display: block !important;
    }


  }

@media only screen 
and (device-width: 844px) 
and (device-height: 390px) 
and (-webkit-device-pixel-ratio: 3) {

    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        /* Add other styles to move the image to a different location here */
      }

}


@media only screen 
and (min-device-width: 320px) 
and (max-device-width: 900px) 
and (orientation: landscape) {

    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        /* Add other styles to move the image to a different location here */
      } 


}

@media only screen and (min-device-width: 768px) and (max-device-width: 834px) and (orientation: portrait) {

    .aboutimage{
        height: 200px;
        width:200px;
        margin: 0 auto; 
        display: block; 
        position: relative;
        top: 18%;
        /* Add other styles to move the image to a different location here */
      } 

    
}

@media only screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-min-device-pixel-ratio: 2) {
    .about-container{
        margin: 0 auto !important; 
        display: block !important; 
    }

}


.about-container{
    margin-top: 5rem;
    display: flex;
}
.about-child{
    margin: 2rem;
    padding: 0rem;
    /* margin-left: 6rem;
    margin-right: 6rem; */
    justify-content: center;
}
/* .hi{
    left: 0.5rem;
    position: relative;
} */
p{
    font-size: 1.1rem;
    line-height: 2.3;
}
.bottomtextsectionline{
    margin-bottom:2rem;
    padding:0;
    margin:2rem
}

hr{
    margin-top: 5rem;
    width: 121.67%;
    position: relative;
    left: -25rem;
    border: none;
    border-top: 2px solid #9E9E9E;
}