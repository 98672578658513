.app{
    font-family: 'Inconsolata', monospace;
}

.navbarcomponent{
    position: sticky;
}
@media only screen 
and (device-width: 390px) 
and (device-height: 844px) 
and (-webkit-device-pixel-ratio: 3) { 

}

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 1rem;
}

.form input {
  display: block;
  width: 100%;
  height: 25px;
  margin: 5px;
  padding: 3px 7px;
  font-size: 17px;
}